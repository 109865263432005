import { Link } from "react-router-dom";
import { resourcesDataLivestock } from "../../data/requestData";
import Select from "react-select";
import { useTranslation } from "react-i18next";
export default function LivestockList() {
  const { t } = useTranslation("help");
  const options = resourcesDataLivestock.map((item) => {
    return { label: item.name, value: item.pdf };
  });

  function comparator(a, b) {
    const firstLetterA = a.label[0].toLowerCase();
    const firstLetterB = b.label[0].toLowerCase();

    if (firstLetterA < firstLetterB) {
      return -1;
    } else if (firstLetterA > firstLetterB) {
      return 1;
    } else {
      return 0;
    }
  }
  function comparatorResource(a, b) {
    const firstLetterA = a.name[0].toLowerCase();
    const firstLetterB = b.name[0].toLowerCase();

    if (firstLetterA < firstLetterB) {
      return -1;
    } else if (firstLetterA > firstLetterB) {
      return 1;
    } else {
      return 0;
    }
  }

  options.sort(comparator);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "darkslategray", // Set your desired border color here
    }),
  };

  return (
    <>
      <div className=" flex justify-between ">
        <div className="text-2xl invisible">Trending Crops</div>
        <Select
          onChange={(item) => window.open(item.value, "_blank", "noreferrer")}
          placeholder={t("Search...")}
          className="dark:text-black w-32 font-bold border-red"
          options={options}
          styles={customStyles}
        />
      </div>
      {/* Grid */}

      <div className="grid sm:grid-cols-2 lg:grid-cols-6 gap-6 mt-5">
        {resourcesDataLivestock?.sort(comparatorResource).map((data) => (
          <Link
            className="group hover:bg-gray-100 rounded-xl transition-all dark:hover:bg-white/[.05]"
            to={data.pdf}
            target="_blank"
          >
            <div className="aspect-w-16 aspect-h-10">
              <img
                className="w-full object-cover rounded-xl"
                src={data.img}
                alt="Image Description"
              />
            </div>
            <div className="pl-2 pb-2">
              <h3 className="mt-3 text-xl font-medium text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
                {data.name}
              </h3>
              <p className="mt-3 inline-flex items-center gap-x-2 text-sm font-regular text-gray-800 dark:text-gray-200">
                {t("Learn more...")}
                <svg
                  className="w-2.5 h-2.5 transition ease-in-out group-hover:translate-x-1"
                  width={16}
                  height={16}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.975821 6.92249C0.43689 6.92249 -3.50468e-07 7.34222 -3.27835e-07 7.85999C-3.05203e-07 8.37775 0.43689 8.79749 0.975821 8.79749L12.7694 8.79748L7.60447 13.7596C7.22339 14.1257 7.22339 14.7193 7.60447 15.0854C7.98555 15.4515 8.60341 15.4515 8.98449 15.0854L15.6427 8.68862C16.1191 8.23098 16.1191 7.48899 15.6427 7.03134L8.98449 0.634573C8.60341 0.268455 7.98555 0.268456 7.60447 0.634573C7.22339 1.00069 7.22339 1.59428 7.60447 1.9604L12.7694 6.92248L0.975821 6.92249Z"
                    fill="currentColor"
                  />
                </svg>
              </p>
            </div>
          </Link>
        ))}
      </div>
      {/* End Grid */}
    </>
  );
}
