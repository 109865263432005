import { useTranslation } from "react-i18next";

export default function ClimateLivestock() {
  const { t } = useTranslation("help");
  return (
    <>
      {/* Card */}
      <div className="text-gray-800 body-font">
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Temperature Score")}
        </h1>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Temperature is a physical quantity that expresses the subjective perceptions of hot and cold. Measured with thermometers, which are calibrated in various temperature scales like Celsius (°C), Fahrenheit (°F), and Kelvin (K), temperature reflects the kinetic energy of particles in a substance. It plays a crucial role in matter's physical state and behavior, affecting phases (solid, liquid, gas), reaction rates, and physical properties."
          )}
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Effect of Temperature on Livestock")}
        </h1>
        <p className="leading-relaxed text-base pb-1">
          {t(
            "The effect of temperature on livestock is profound and multifaceted, impacting their health, behavior, reproduction, and productivity. Animals, like humans, have a thermoneutral zone (TNZ), a range of temperatures in which they can maintain their body temperature without expending extra energy. Deviations outside this zone can lead to thermal stress, with high and low temperatures presenting unique challenges."
          )}
        </p>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("1. Heat Stress:")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Decreased Feed Intake:")}</strong>{" "}
            {t(
              "Higher temperatures can reduce an animal's appetite, leading to lower feed intake and potential weight loss."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Reduced Productivity:")}</strong>{" "}
            {t(
              "Heat stress can lead to significant drops in milk production for dairy cows. In meat-producing animals, it can slow growth rates."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Reproductive Issues:")}</strong>{" "}
            {t(
              "Heat stress can impair fertility, leading to lower conception rates and affecting the overall reproductive performance."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Health Problems:")}</strong>{" "}
            {t(
              "It increases susceptibility to diseases, can cause dehydration, and, in severe cases, lead to heatstroke and death."
            )}
          </li>
        </ul>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("2. Cold Stress:")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Increased Energy Needs:")}</strong>{" "}
            {t(
              "Animals require more energy to maintain body temperature in cold environments, often leading to increased feed consumption."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Frostbite and Hypothermia")}</strong>{" "}
            {t(
              "In extreme cold, animals can suffer frostbite and hypothermia, particularly those without adequate shelter or body fat reserves."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Impaired Immune Function")}</strong>{" "}
            {t(
              "Cold stress can weaken the immune system, making animals more susceptible to respiratory and other diseases."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Reproductive Performance")}</strong>{" "}
            {t(
              "Like heat stress, cold stress can also affect reproductive efficiency, with impacts on libido and fertility in both males and females."
            )}
          </li>
        </ul>

        <p className="leading-relaxed text-base pb-3">
          {t(
            "Understanding and managing the effects of temperature on livestock is crucial for maintaining animal welfare, ensuring productivity, and achieving sustainable agricultural practices."
          )}
        </p>
        <p className="leading-relaxed text-base pb-3">
          <strong>{t("The Temperature Humidity Index (THI)")}</strong>{" "}
          {t(
            "is an integrated measure that combines air temperature and humidity to assess environmental stress on animals, particularly focusing on the conditions that may lead to heat stress. THI is critical in understanding how combined temperature and moisture levels in the air affect livestock health, comfort, and performance. Since animals rely on their surroundings to regulate body temperature, air temperature, and humidity are pivotal in influencing their thermal balance."
          )}
        </p>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("How THI Works")}
        </h1>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "The THI considers that as humidity increases, the effectiveness of heat dissipation through evaporation (such as sweating in humans or panting in animals) decreases. This reduced efficiency of body cooling mechanisms can lead to stress, even at moderate temperatures. The index is calculated using various formulae that input temperature and relative humidity data, producing a value that helps quantify the level of heat stress animals might experience."
          )}
        </p>

        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("Effects of THI on Livestock")}
        </h1>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("1. Heat Stress:")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Reduced Feed Intake and Weight Gain:")}</strong>{" "}
            {t(
              "Elevated THI levels can decrease appetite and feed conversion efficiency, resulting in lower weight gain in meat-producing animals and reduced milk yield in dairy cattle."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Decreased Reproductive Efficiency:")}</strong>{" "}
            {t(
              "Heat stress affects both male and female reproduction, leading to lower fertility rates, increased embryonic mortality, and overall reduced reproductive performance."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Milk Production Decline:")}</strong>{" "}
            {t(
              "Dairy cows are particularly sensitive to heat stress, with high THI levels leading to a significant drop in milk production and changes in milk composition."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Health Issues:")}</strong>{" "}
            {t(
              "Increased THI values are associated with a higher incidence of health problems, including respiratory difficulties, heat exhaustion, and in severe cases, mortality. These health issues are due to the animals' struggle to dissipate body heat, leading to overheating and stress."
            )}
          </li>
        </ul>
        <h1 className="pb-2 pt-2 text-lg font-medium text-gray-900 dark:text-white">
          {t("2. Behavior Changes:")}
        </h1>
        <ul className="list-disc list-inside p-3">
          <li className="mb-2">
            <strong>{t("Seeking Shade and Water:")}</strong>{" "}
            {t(
              "Animals naturally seek shade and water to cool down, leading to overcrowding in certain areas and increased competition for resources."
            )}
          </li>
          <li className="mb-2">
            <strong>{t("Altered Feeding Patterns:")}</strong>{" "}
            {t(
              "Livestock may alter their feeding behavior, feeding more during cooler parts of the day and less during peak heat, affecting their overall nutrient intake and health"
            )}
          </li>
        </ul>
        <p className="leading-relaxed text-base pb-3">
          {t(
            "By closely monitoring THI and implementing appropriate management strategies, livestock producers can significantly reduce the adverse effects of heat stress, thereby improving animal welfare, productivity, and overall farm profitability."
          )}
        </p>
      </div>

      {/* End Card */}
    </>
  );
}
